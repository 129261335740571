const { axiosCreate } = require('./Api')
const { checkResponse } = require('../controllers/errorHandler')
import store from '../state/store'

/**
 * Create a new item for a customer
 * @param {string} itemid New item for the customer
 * @param {string} comment A comment for the customer to identify a relation to the item
 * @param {string} customerID Customer ID that the user is logged in for
 * @returns Array containing all found serial records
 */
async function addItem({
  itemid,
  itemdescription,
  comment,
  publicSerials,
  customerID
}) {
  const result = await axiosCreate.post(
    `/shop/customer/${customerID}/customeritems`,
    {
      CustomerID: customerID,
      ItemID: itemid,
      Description: itemdescription,
      MediumDescription: comment,
      PublicSerials: publicSerials
    },
    {
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data
}

/**
 * Delete Customer's item
 * @param {string} ItemID ID of the customer's item
 * @param {string} CustomerID The ID of  the customer you are logged in with
 * @returns {object} Server success object
 */
async function deleteItem({ ItemID, CustomerID }) {
  const result = await axiosCreate.delete(
    `/shop/customer/${CustomerID}/customeritems/${ItemID}`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data
}

/**
 * Get Customer's own items
 * @param {string} customerID
 * @returns Array containing all found item objects they own themselves
 */
async function getItems({ customerID }) {
  const result = await axiosCreate.get(
    `/shop/customer/${customerID}/customeritems`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data.Collection
}

/**
 * Get Customer's item
 * @param {string} customerID
 * @param {string} itemID
 * @returns Customer item object
 */
async function getItem({ customerID, itemID }) {
  const result = await axiosCreate.get(
    `/shop/customer/${customerID}/customeritems/${itemID}`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data
}

/**
 * Modify Customer's item
 * @param {string} customerID
 * @param {string} itemID
 * @returns Customer item object
 */
async function modifyItem({
  customerID,
  itemID,
  Description,
  MediumDescription
}) {
  const result = await axiosCreate.patch(
    `/shop/customer/${customerID}/customeritems/${itemID}`,
    {
      Description,
      MediumDescription
    },
    {
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data
}

/**
 * Get Customer's rented items
 * @param {string} customerID
 * @returns Array containing all found item objects for provided customerID
 */
async function getRentedItems({ customerID }) {
  const result = await axiosCreate.get(
    `/shop/customer/${customerID}/renteditems`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data.Collection
}

module.exports = {
  getItems,
  getRentedItems,
  addItem,
  getItem,
  modifyItem,
  deleteItem
}

<template>
  <div>
    <b-button v-b-modal.newitem class="mb-3">Nieuw artikel aanmaken</b-button>

    <b-modal id="newitem" hide-footer title="Nieuw artikel toevoegen">
      <form @submit="onSubmit">
        <div
          id="itemidlabel"
          class="form-group"
          label="Artikelnummer"
          label-for="itemid"
        >
          <label>Artikelnummer</label>
          <input
            id="itemid"
            v-model="form.itemid"
            class="form-control"
            maxlength="20"
            type="text"
            placeholder="Voer het artikelnummer in"
            required
          />
        </div>

        <div class="form-group">
          <label>Omschrijving</label>
          <input
            id="itemdescription"
            v-model="form.itemdescription"
            class="form-control"
            placeholder="Geef een omschrijving van het artikel op"
            required
          />
        </div>

        <div
          id="commentlabel"
          class="form-group"
          label="Opmerking"
          label-for="comment"
        >
          <label>Opmerking</label>
          <textarea
            id="comment"
            v-model="form.comment"
            class="form-control"
            placeholder="Geef eventueel een opmerking op"
            rows="3"
            max-rows="6"
          ></textarea>
        </div>


        <div class="w-100 mt-2">
          <button class="btn btn-primary" type="submit">
            Artikel aanmaken
          </button>
          <button class="btn btn-secondary float-right" @click="hideModal()">
            Sluiten
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { addItem } from '@/services/CustomerItemService'

import { BModal, BButton } from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BButton
  },
  data() {
    return {
      form: {
        itemid: '',
        itemdescription: '',
        comment: '',
      }
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide(`newitem`)
    },
    async onSubmit(event) {
      event.preventDefault()

      const result = await addItem({
        itemid: this.form.itemid,
        itemdescription: this.form.itemdescription,
        comment: this.form.comment,
        publicSerials: this.form.publicSerials,
        customerID: this.$store.state.customerID
      })

      if (result.Description === this.form.itemdescription) {
        this.form.itemid = ''
        this.form.itemdescription = ''
        this.form.comment = ''
        this.form.publicSerials = ''
        this.$emit('item-added')
        this.hideModal()
        this.$toasted.show(`Artikel ${result.Description} is aangemaakt`)
        return
      }
      this.$toasted.show(`Er is iets mis gegaan, probeer het later opnieuw`)
    }
  }
}
</script>

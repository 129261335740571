var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Mijn artikelen")]),_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend metus enim, eu tristique dui sollicitudin sit amet. Sed interdum, felis quis tincidunt condimentum, nisl elit tempor justo, dictum pulvinar velit metus eu eros. ")]),_c('items-modal-create',{ref:"modifyItemModal",on:{"item-added":_vm.getItems}}),_c('b-modal',{attrs:{"id":"deleteItem","title":"Weet je het zeker?","hide-footer":"","hide-header":""},on:{"ok":function($event){return _vm.deleteItem({
        ItemID: _vm.deleteModal.itemID
      })}}},[_c('div',{staticClass:"pb-4"},[_c('h5',[_vm._v("Opgelet!")]),_c('strong',[_vm._v(" Weet je zeker dat je "),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.deleteModal.description))]),_vm._v(" wilt verwijderen? ")])]),_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.deleteItem({
            ItemID: _vm.deleteModal.itemID
          })}}},[_vm._v("Verwijderen")]),_c('b-button',{on:{"click":function($event){return _vm.$bvModal.hide("deleteItem")}}},[_vm._v("Annuleren")])],1)]),_c('items-modal-edit',{ref:"modifyItemModal",staticClass:"mr-2",attrs:{"itemid":_vm.modifyModal.itemID,"itemdescription":_vm.modifyModal.itemDescription,"mediumdescription":_vm.modifyModal.mediumDescription},on:{"item-changed":_vm.getItems}}),(_vm.items.length > 0)?_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"cell(ReportID)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[_c('items-modal-info',{attrs:{"itemid":data.item.ItemID}}),_c('b-button',{staticClass:"mb-3 ml-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.openModifyItem({
              itemID: data.item.ItemID,
              itemDescription: data.item.Description,
              mediumDescription: data.item.MediumDescription
            })}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1),_c('b-button',{staticClass:"mb-3 ml-1",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.openDeleteItem({
              itemID: data.item.ItemID,
              description: data.item.Description
            })}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1)],1)]}}],null,false,3554292108)}):_c('p',[_vm._v(" Je hebt nog geen artikelen om te bekijken. Wanneer je nieuwe artikelen aanmaakt zullen deze hier worden getoond. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
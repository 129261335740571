import { render, staticRenderFns } from "./ItemsModalCreate.vue?vue&type=template&id=3b6c2898&"
import script from "./ItemsModalCreate.vue?vue&type=script&lang=js&"
export * from "./ItemsModalCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.tsa/test/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BModal} from 'bootstrap-vue'
    installComponents(component, {BButton, BModal})
    


    import installDirectives from "!D:/IIS/rentmagic.website.vue.tsa/test/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBModal} from 'bootstrap-vue'
    installDirectives(component, {'b-modal': VBModal})
    

export default component.exports
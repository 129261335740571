<template>
  <div>
    <h4>
      Artikel gegevens
    </h4>
    <table class="table">
      <tr>
        <td><strong>Artikelnummer:</strong></td>
        <td>{{ item.ItemID }}</td>
      </tr>
      <tr>
        <td><strong>Omschrijving:</strong></td>
        <td>{{ item.Description }}</td>
      </tr>
      <tr>
        <td><strong>Transport gewicht:</strong></td>
        <td>{{ item.ShippingWeight || 'N/A' }}</td>
      </tr>
    </table>

    <strong>Omschrijving:</strong>
    <hr />
    <div v-html="item.MediumDescription" />
    <div v-html="item.LongDescription" />
  </div>
</template>

<script>
import { getItem } from '@/services/CustomerItemService'
export default {
  props: {
    itemid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      item: {}
    }
  },
  async created() {
    this.item = await getItem({
      itemID: this.itemid,
      customerID: this.$store.state.customerID
    })
  }
}
</script>

<style></style>

<template>
  <div>
    <b-modal
      :id="`modaledit-${itemid}`"
      :title="`Artikel ${itemdescription} aanpassen`"
      hide-footer
    >
      <b-form v-if="itemid" @submit="onSubmit">
        <b-form-group id="itemidlabel" label="Artikelnummer" label-for="itemid">
          <b-form-input
            id="itemid"
            v-model="form.itemid"
            :readonly="true"
            type="text"
            placeholder="Voer het artikelnummer in"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="itemdescriptionlabel"
          label="Omschrijving"
          label-for="itemdescription"
        >
          <b-form-input
            id="itemdescription"
            v-model="form.itemdescription"
            placeholder="Geef een omschrijving van het artikel op"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="commentlabel" label="Opmerking" label-for="comment">
          <b-form-textarea
            id="comment"
            v-model="form.mediumDescription"
            placeholder="Geef eventueel een opmerking op"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <div class="w-100 mt-4">
          <b-button type="submit" variant="primary">Artikel bijwerken</b-button>
          <b-button
            type="submit"
            variant="secondary"
            class="float-right"
            @click="hideModal()"
          >
            Sluiten
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { modifyItem } from '@/services/CustomerItemService'

import {
  BModal,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea
} from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,

    BFormTextarea
  },
  props: {
    itemid: {
      required: true,
      type: String,
      default: function() {
        return Math.floor(Math.random() * 100000) + 1
      }
    },
    itemdescription: {
      required: true,
      type: String,
      default: ''
    },
    mediumdescription: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        itemid: '',
        itemdescription: '',
        mediumDescription: ''
      },
      show: true
    }
  },
  created: function() {
    this.form = {
      itemid: this.itemid,
      itemdescription: this.itemdescription,
      mediumDescription: this.mediumdescription
    }
  },
  watch: {
    itemid() {
      this.form = {
        itemid: this.itemid,
        itemdescription: this.itemdescription,
        mediumDescription: this.mediumdescription
      }
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show(`modaledit-${this.itemid}`)
    },
    hideModal() {
      this.$bvModal.hide(`modaledit-${this.itemid}`)
    },
    async onSubmit(event) {
      event.preventDefault()

      const result = await modifyItem({
        itemID: this.form.itemid,
        customerID: this.$store.state.customerID,
        MediumDescription: this.form.mediumDescription,
        Description: this.form.itemdescription
      })

      if (result.Description === this.form.itemdescription) {
        this.$emit('item-changed')
        this.hideModal()
      }
    }
  }
}
</script>

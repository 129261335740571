<template>
  <div>
    <h3>Mijn artikelen</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend
      metus enim, eu tristique dui sollicitudin sit amet. Sed interdum, felis
      quis tincidunt condimentum, nisl elit tempor justo, dictum pulvinar velit
      metus eu eros.
    </p>

    <items-modal-create ref="modifyItemModal" @item-added="getItems" />

    <b-modal
      :id="`deleteItem`"
      title="Weet je het zeker?"
      hide-footer
      hide-header
      @ok="
        deleteItem({
          ItemID: deleteModal.itemID
        })
      "
    >
      <div class="pb-4">
        <h5>Opgelet!</h5>
        <strong>
          Weet je zeker dat je
          <span class="text-primary">{{ deleteModal.description }}</span>
          wilt verwijderen?
        </strong>
      </div>
      <div class="w-100">
        <b-button
          variant="primary"
          class="mr-2"
          @click="
            deleteItem({
              ItemID: deleteModal.itemID
            })
          "
          >Verwijderen</b-button
        >
        <b-button @click="$bvModal.hide(`deleteItem`)"
          >Annuleren</b-button
        >
      </div>
    </b-modal>

    <items-modal-edit
      ref="modifyItemModal"
      class="mr-2"
      :itemid="modifyModal.itemID"
      :itemdescription="modifyModal.itemDescription"
      :mediumdescription="modifyModal.mediumDescription"
      @item-changed="getItems"
    />

    <b-table v-if="items.length > 0" :items="items" :fields="fields" responsive>
      <template v-slot:cell(ReportID)="data">
        <div class="d-flex">
          <items-modal-info :itemid="data.item.ItemID" />

          <b-button
            size="sm"
            class="mb-3 ml-1"
            @click="
              openModifyItem({
                itemID: data.item.ItemID,
                itemDescription: data.item.Description,
                mediumDescription: data.item.MediumDescription
              })
            "
            ><font-awesome-icon icon="edit"
          /></b-button>

          <b-button
            size="sm"
            variant="danger"
            class="mb-3 ml-1"
            @click="
              openDeleteItem({
                itemID: data.item.ItemID,
                description: data.item.Description
              })
            "
          >
            <font-awesome-icon icon="trash" />
          </b-button>
        </div>
      </template>
    </b-table>

    <p v-else>
      Je hebt nog geen artikelen om te bekijken. Wanneer je nieuwe artikelen
      aanmaakt zullen deze hier worden getoond.
    </p>
  </div>
</template>

<script>
import ItemsModalCreate from './ItemsModalCreate.vue'
import ItemsModalEdit from './ItemsModalEdit.vue'
import ItemsModalInfo from './ItemsModalInfo.vue'

import { getItems, deleteItem } from '@/services/CustomerItemService'

import { BTable, BButton } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    ItemsModalCreate,
    ItemsModalEdit,
    ItemsModalInfo,
    BButton
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'ItemID',
          label: 'Artikel Nr.'
        },
        {
          key: 'Description',
          label: 'Omschrijving'
        },
        {
          key: 'ReportID',
          label: 'Acties'
        }
      ],
      deleteModal: {
        itemID: null,
        description: null
      },
      modifyModal: {
        itemID: '',
        itemDescription: '',
        mediumDescription: ''
      }
    }
  },
  created: async function() {
    this.getItems()
  },
  methods: {
    async getItems() {
      this.items = await getItems({ customerID: this.$store.state.customerID })
    },
    async deleteItem({ ItemID }) {
      this.$bvModal.hide(`deleteItem`)
      await deleteItem({
        CustomerID: this.$store.state.customerID,
        ItemID
      })

      this.$toasted.show(`Artikel ${ItemID} is verwijderd`)

      this.getItems()
    },
    async openDeleteItem({ itemID, description }) {
      this.deleteModal.itemID = itemID
      this.deleteModal.description = description

      this.$bvModal.show(`deleteItem`)
    },
    async openModifyItem({ itemID, itemDescription, mediumDescription }) {
      this.modifyModal.itemID = itemID
      this.modifyModal.itemDescription = itemDescription
      this.modifyModal.mediumDescription = mediumDescription

      this.$refs.modifyItemModal.showModal()
    }
  }
}
</script>

<style scss></style>

<template>
  <div>
    <b-button
      :v-b-modal="`modal-${itemid}`"
      class="mb-3"
      size="sm"
      variant="primary"
      @click="showModal"
      ><font-awesome-icon icon="info-circle"
    /></b-button>

    <b-modal
      :id="`modal-${itemid}`"
      size="lg"
      :title="`Artikel informatie ${itemid}`"
    >
      <b-tabs v-if="itemid" content-class="mt-3">
        <b-tab title="Artikel" active
          ><items-modal-info-tab-item :itemid="itemid"
        /></b-tab>
        <!-- <b-tab title="Bestanden">
           <file-download :key="key" :type="type"/> 
        </b-tab> -->
      </b-tabs>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="hideModal()"
          >
            Sluiten
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BTabs, BTab } from 'bootstrap-vue'
// import FileDownload from './FileDownload'

import ItemsModalInfoTabItem from './ItemsModalInfoTabItem.vue'

import { getItem } from '@/services/CustomerItemService'

export default {
  components: {
    BModal,
    BButton,
    BTabs,
    BTab,
    ItemsModalInfoTabItem
    // FileDownload,
  },
  props: {
    itemid: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      item: null
    }
  },

  methods: {
    async showModal() {
      this.$bvModal.show(`modal-${this.itemid}`)
      this.item = await getItem({
        customerID: this.$store.state.customerID,
        itemID: this.itemid
      })
    },
    hideModal() {
      this.$bvModal.hide(`modal-${this.itemid}`)
    }
  }
}
</script>

import { render, staticRenderFns } from "./ItemsModalEdit.vue?vue&type=template&id=1d2a4f41&"
import script from "./ItemsModalEdit.vue?vue&type=script&lang=js&"
export * from "./ItemsModalEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.tsa/test/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BFormGroup, BFormTextarea, BButton, BForm, BModal} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BFormGroup, BFormTextarea, BButton, BForm, BModal})
    

export default component.exports